//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from "vuex";
import { ctrlAccount } from "@/controller";
import { Upload, Message, Radio, RadioGroup, Button, Form, FormItem, Input } from "element-ui";
export default {
    components: {
        [Upload.name]: Upload,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
        [Button.name]: Button,
        [Form.name]: Form,
        [FormItem.name]: FormItem,
        [Input.name]: Input,
    },
    data() {
        return {
            profile: {
                SnsTypes: [],
            }, //會員訊息
            orgin: {},
            isOrgin: true,
        };
    },
    created() {},
    computed: {
        ...mapState({
            //validateMsg
            msg: function(state) {
                return state["validate"];
            },
            userInfo: function(state) {
                const userInfo = state.user.userInfo;
                return userInfo;
            },
        }),
    },
    watch: {
        profile: {
            handler: function(newValue, oldValue) {
                this.isOrgin = JSON.stringify(newValue) == this.orgin;
            },
            deep: true,
        },
        userInfo: {
            handler: function(newval) {
                this.getUserInfo();
            },
            immediate: true,
        },
    },
    methods: {
        getUserInfo() {
            this.profile = JSON.parse(JSON.stringify(this.userInfo));
            this.orgin = JSON.stringify(this.profile);
        },
        //保存會員訊息
        save(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (!valid) {
                    console.log("error submit!!");
                    return false;
                }
                let obj = {
                    memName: this.profile.memName,
                    gender: this.profile.gender,
                    mobile: this.profile.mobile,
                    avatar: this.profile.imgFile ? this.profile.imgFile : "",
                    email: this.profile.email,
                };
                console.log(obj);
                let data = await ctrlAccount.updMemInfo(obj);
                if (data) {
                    Message.success("上傳成功");
                    const user = await ctrlAccount.getSenMemInfo();
                    if (this.Common.isExist(user)) {
                        this.$store.dispatch("user/setUserInfo", user);
                        this.getUserInfo();
                    }
                    // this.$bus.$emit("updMemInfo", true);
                }
            });
        },
        handleAvatarSuccess(res, file) {
            console.log(res.raw);
            this.profile.imgFile = res.raw;
            this.profile.avatarUrl = URL.createObjectURL(res.raw);
        },
        // beforeAvatarUpload(file) {
        //     const isJPG = file.type === "image/jpeg";
        //     const isGIF = filesList[i].raw.type === "image/gif";
        //     const isPNG = filesList[i].raw.type === "image/png";
        //     const isBMP = filesList[i].raw.type === "image/bmp";
        //     const isLt4M = file.size / 1024 / 1024 < 4;
        //     if (!isJPG && !isGIF && !isPNG && !isBMP) {
        //         this.$message.error("上傳圖片必須是JPG/GIF/PNG/BMP 格式!");
        //     }
        //     if (!isLt4M) {
        //         this.$message.error("上傳頭像圖片大小不能超過 4MB!");
        //     }
        //     return isJPG && isGIF && isPNG && isBMP && isLt4M;
        // },

        async getSignout() {
            const data = await ctrlAccount.signout();
            if (data) this.$store.dispatch("user/setUserInfo", {});
        },
    },
};
