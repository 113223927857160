var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile"},[_c('div',{staticClass:"pg-title"},[_vm._v(" 個人資訊 ")]),(_vm.profile)?_c('div',{staticClass:"profile__content"},[_c('el-form',{ref:"ruleForm",attrs:{"model":_vm.profile}},[_c('div',{staticClass:"profile__info"},[_c('div',[_c('span',{staticClass:"profile-label"},[_vm._v("用戶名稱")]),_c('el-form-item',{attrs:{"prop":"memName","rules":[
                            {
                                trigger: 'blur',
                                required: true,
                                message: _vm.msg.req,
                            } ]}},[_c('el-input',{model:{value:(_vm.profile.memName),callback:function ($$v) {_vm.$set(_vm.profile, "memName", $$v)},expression:"profile.memName"}})],1)],1),_c('div',[_c('span',{staticClass:"profile-label"},[_vm._v("性別")]),_c('span',[_c('el-radio-group',{model:{value:(_vm.profile.gender),callback:function ($$v) {_vm.$set(_vm.profile, "gender", $$v)},expression:"profile.gender"}},[_c('el-radio',{attrs:{"label":"M"}},[_vm._v("男")]),_c('el-radio',{attrs:{"label":"F"}},[_vm._v("女")])],1)],1)]),_c('div',{staticClass:"pg-title"},[_vm._v(" 帳戶安全 ")]),(_vm.profile.accountType === 'email')?_c('div',[_c('span',{staticClass:"profile-label"},[_vm._v("Email")]),_c('span',[_vm._v(_vm._s(_vm.profile.email))])]):_c('div',[_c('span',{staticClass:"profile-label"},[_vm._v("Email")]),_c('el-form-item',{attrs:{"prop":"email","rules":[
                            {
                                validator: _vm.msg.eleIsEmail,
                                trigger: 'blur',
                                required: true,
                            } ]}},[_c('el-input',{model:{value:(_vm.profile.email),callback:function ($$v) {_vm.$set(_vm.profile, "email", $$v)},expression:"profile.email"}})],1)],1),(_vm.profile.accountType === 'mobile')?_c('div',[_c('span',{staticClass:"profile-label"},[_vm._v("綁定手機")]),_c('span',[_vm._v(_vm._s(_vm.profile.mobile))])]):_c('div',[_c('span',{staticClass:"profile-label"},[_vm._v("手機號碼")]),_c('el-form-item',{attrs:{"prop":"mobile","rules":[
                            {
                                validator: _vm.msg.eleIsMobile,
                                trigger: 'blur',
                                required: true,
                            } ]}},[_c('el-input',{model:{value:(_vm.profile.mobile),callback:function ($$v) {_vm.$set(_vm.profile, "mobile", $$v)},expression:"profile.mobile"}})],1)],1)])]),_c('div',{staticClass:"profile__upload"},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":"global","auto-upload":false,"show-file-list":false,"on-change":_vm.handleAvatarSuccess,"accept":".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"}},[(_vm.profile.avatarUrl)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.profile.avatarUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})]),_c('div',{staticClass:"profile__upload-tips"},[_vm._v(" 僅支持JPG、GIF、PNG、JPEG、BMP格式，檔案小於4M ")])],1)],1):_vm._e(),_c('div',{staticClass:"profile__bottom"},[_c('el-button',{staticClass:"profile__btn",attrs:{"type":"primary","disabled":this.isOrgin},on:{"click":function($event){return _vm.save('ruleForm')}}},[_vm._v("儲存訊息")]),_c('el-button',{staticClass:"signout__btn",attrs:{"plain":""},on:{"click":_vm.getSignout}},[_vm._v("退出帳號")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }